import React, { Component } from "react";
import GrievanceHeader from "./General/GrievanceHeader";
import GrievanceAction from "../../store/grievance/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AUX from "../../hoc/Aux_";
import { MDBInput } from "mdbreact";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Services from "./GrievanceService";
import { toast } from "react-toastify";
import authAxios from "../Shared/auth-header";
import { isMobile } from "react-device-detect";
import AuthService from "../../modules/Auth/AuthService";
import moment from "moment";
import $ from "jquery";
import _ from "lodash";
import { PdfDocument } from "../../modules/Grievances/General/GrievanceStepPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import GrievanceSearchModal from "./General/GrivanceSearchModal";
import Tooltip from "./General/Tooltip";
class ReviewGrievances extends Component {
  constructor(props) {
    super(props);
    super(props);
    this.state = {
      sizePerPage: 10,
      isClean: true,
      showClearButton: false,
      show: false,
      loadState: true,
      page: 1,
      customSortApplied: false,
      expanded: [],
      searchTerm: "",
      searchType: "",
      pdfData: {},
      grievancesData: [],
      completeGrievancesList: [],
      filteredGrievances: [],
      ActiveTab: -1,
      regions: [],
      facilities: [],
      searchTermStatus: "",
      searchTermRegion: "",
      searchTermFacility: "",
      searchTermLR: "",
      searchTermArticle: "",
      searchTermContract: "",
      contractList: [],
      articlesList: [],
      searchTermGRT: "",
      grievanceCount: 0,
      searchkeyword: "",
      statuses: [
        { statusId: 1, status: "Draft" },
        { statusId: 2, status: "Active" },
        { statusId: 3, status: "Held in Abeyance" },
        { statusId: 4, status: "Remanded" },
        { statusId: 5, status: "Sustained" },
        { statusId: 6, status: "Denied" },
        { statusId: 7, status: "Withdrawn" },
        { statusId: 8, status: "Settled" },
      ],
    };
    this.rowClasses = (row, rowIndex) => {
      let classes = null;
      if (row.status == "Draft") {
        classes = "bg-light-blue";
      }
      if (row.canEdit) {
        classes = "c-pointer";
      }
      return classes;
    };
    this.RemotePagination = this.RemotePagination.bind(this);
    this.SizePerPageChange = this.SizePerPageChange.bind(this);
    this.filterMyRequest = this.filterMyRequest.bind(this);
    this.viewAllLink = this.viewAllLink.bind(this);
    this.sortDataArticles = this.sortDataArticles.bind(this);
    this.getColumn = this.getColumn.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.expandRow = this.expandRow.bind(this);
    this.OnPageChange = this.OnPageChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.expandFirst = this.expandFirst.bind(this);
    this.loadAllContracts = this.loadAllContracts.bind(this);
    this.filterArticlesBasedOnContracts = this.filterArticlesBasedOnContracts.bind(this);
    this.expandAll = this.expandAll.bind(this);
  }
  expandFirst() {
    let expanded = [];
    if (
      this.state.filteredGrievances &&
      !_.isEmpty(this.state.filteredGrievances)
    ) {
      expanded = [...expanded, "Draft", "Active"];
    }

    this.setState({
      expanded: expanded,
    });
    setTimeout(() => {
      this.setState({ show: true });
    }, 2000);
  }
  expandAll() {
    let expanded = [];
    if (
      this.state.filteredGrievances &&
      !_.isEmpty(this.state.filteredGrievances)
    ) {
      this.state.filteredGrievances.forEach((item) => {
        expanded = [...expanded, item.status];
      });
    }

    this.setState({
      expanded: expanded,
    });
    setTimeout(() => {
      this.setState({ show: true });
    }, 2000);
  }

  changeActiveTab(value) {
    this.setState({ ActiveTab: value });
  }
  loadAllRequests() {
    let grievancesList = [];
    Services.LoadGrievanceByRepresentative(true, false).then((result) => {
      if (!_.isEmpty(result.data)) {
        _.forEach(result.data, function (el, index, arr) {
          grievancesList.push({
            dateFiled: el.dateFiled ? el.dateFiled : null,
            facilityId: el.facilityId ? el.facilityId : null,
            facility: isMobile ? (el.facilityCode ? el.facilityCode : null) : (el.facilityName ? el.facilityName : null),
            grievanceId: el.grievanceId ? el.grievanceId : null,
            region: el.regionName ? el.regionName : null,
            assignedUserName: el.assignedUserName ? el.assignedUserName : null,
            assignedGRTUserName: el.assignedGRTUserName ? el.assignedGRTUserName : null,
            assignedGRTUserId: el.assignedGRTUserId ? el.assignedGRTUserId : null,
            assignedUserId: el.assignedUserId ? el.assignedUserId : null,
            articles: el.articles ? el.articles : null,
            articleName: el.articleName ? el.articleName : null,
            articleId: el.articleId ? el.articleId : null,
            contractId: el.contractId ? el.contractId : null,
            gId: el.id ? el.id : null,
            grievant: el.grievantName ? el.grievantName : null,
            regionId: el.regionId ? el.regionId : null,
            status: el.status ? el.status : null,
            step: el.step ? el.step : null,
            canEdit: el.canEdit ? el.canEdit : null,
            representativeName: el.representativeName ? el.representativeName : null,
            subject: el.grievanceSubject ? el.grievanceSubject : null,
            remainingDays: el.daysRemainingForNextDueDate ? parseInt(el.daysRemainingForNextDueDate) : null,
            pdfData: el.pdfData ? el.pdfData : null,
          });
        });
        this.setState({
          filteredGrievances: this.arrangeDataWithStatus(
            _.cloneDeep(grievancesList),
            10,
            1
          ),
          grievancesData: grievancesList,
          completeGrievancesList: grievancesList,
          sizePerPage: 10,
          page: 1,
          customSortApplied: true,
          grievanceCount: grievancesList.length,
        });
        this.expandFirst();
        this.setState({
          loadState: false,
        });
        $(".loading").hide();
      }
    });
  }
  sortDataArticles = (grievancesData, sortOrder) => {
    const data = grievancesData;

    return data.sort((a, b) => {
      // Assuming each item in completeGrievancesList has an 'article' property
      const articleIndexA =
        a.articles && !_.isEmpty(a.articles) ? a.articles[0].articleIndex : 0;
      const articleIndexB =
        b.articles && !_.isEmpty(b.articles) ? b.articles[0].articleIndex : 0;
      if (sortOrder === "asc") {
        return articleIndexA - articleIndexB;
      } else {
        return articleIndexB - articleIndexA;
      }
    });
  };
  arrangeDataWithStatus(List, sizePerPage, page) {
    let start = (page - 1) * sizePerPage,
      end = (page - 1) * sizePerPage - 1 + sizePerPage + 1;
    $(".loading").show();
    let List2 = List;
    if (this.state.customSortApplied == false) {
      List2 = _.orderBy(
        List2,
        [
          (item) => (item.status === "Draft" ? 0 : 1), // Make "Draft" come first
          "status",
          "remainingDays",
          "gId",
        ],
        ["asc", "asc", "asc", "desc"]
      );
    }
    List2 = List2.slice(start, end);
    let result = this.state.statuses
      .map((x) => {
        //let sizePerPage = this.state.sizePerPage;
        //let page = this.state.page,
        let data = List2.filter((y) => y.status == x.status);
        if (data && data.length > 0) {
          return {
            status: x.status,
            grievances: data,
          };
        }
      })
      .filter((el) => el);
    $(".loading").hide();
    return result;
  }

  loadRegions() {
    let regionsList = [];
    authAxios.get("/api/Grievance/GetRegions").then(({ data }) => {
      if (!_.isEmpty(data.data)) {
        _.forEach(data.data, function (el, index, arr) {
          regionsList.push({
            regionId: el.id,
            region: el.code,
          });
        });
        this.setState({
          regions: regionsList,
        });
      }
    });
  }
  componentDidMount() {
    $(".loading").show();
    this.loadRegions();
    this.loadArticles();
    this.loadAllContracts()
    Services.GetRepresentativeRole().then((response) => {
      if (this.props.grievanceState.isRepresentative && response.data) {
        this.loadAllRequests();
        var grievanceCreateSuccessMsg = this.props.history.location.toastMsg;
        if (!_.isEmpty(grievanceCreateSuccessMsg)) {
          toast.success(grievanceCreateSuccessMsg);
          this.setState({ ActiveTab: 2 });
        }
      }
    });
    $(".loading").hide();
  }
  handleTableChange = async (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    console.log("type", type);
    if (type === "sort") {
      if (sortField == "articleName") {
        this.state.grievancesData = this.sortDataArticles(
          this.state.grievancesData,
          sortOrder
        );
        this.state.completeGrievancesList = this.sortDataArticles(
          this.state.completeGrievancesList,

          sortOrder
        );
      } else {
        this.state.grievancesData = _.orderBy(
          this.state.grievancesData,
          [sortField],
          [sortOrder]
        );
        this.state.completeGrievancesList = _.orderBy(
          this.state.completeGrievancesList,
          [sortField],
          [sortOrder]
        );
      }
      await this.setState({
        completeGrievancesList: this.state.completeGrievancesList,
        grievancesData: this.state.grievancesData,
        filteredGrievances: this.arrangeDataWithStatus(
          this.state.grievancesData,
          this.state.sizePerPage,
          this.state.page
        ),
        customSortApplied: true,
      });
    }
  };
    grievancePage = (e, row) => {
        debugger;
    if (row.canEdit && e.target.className.indexOf("pdf") == -1) {
      this.props.history.push({
        pathname: "/grievances/" + row.grievanceId,
        type: "create",
        uid: row.grievanceId,
      });
    }
  };
  expandRow = () => {
    return {
      renderer: (row) => {
        return (
          <>
            {!_.isEmpty(row.grievances) &&
              row.grievances.map((item, index) => (
                <div
                      key={"expend-" + row.status + "-" + index}
                      className={"d-flex px-0 w-100 border-top border-bottom" + (row.canEdit ? " c-pointer" : "")}
                  onClick={(e) => {
                          
                    this.grievancePage(e, item);
                  }}
                >
                  {isMobile ? (
                    <>
                      <div
                        className="px-3 py-2 border-right"
                        style={{ width: "25%" }}
                      >
                        {item.grievant}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "30%" }}
                      >
                        {moment(item.dateFiled).isValid()
                          ? moment(item.dateFiled).format("MM/DD/YYYY")
                          : ""}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "22%" }}
                      >
                        {item.region}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "23%" }}
                      >
                        {item.facility}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="px-3 py-2 border-right"
                        style={{ width: "10%" }}
                      >
                        {item.grievant}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "10%" }}
                      >
                        {moment(item.dateFiled).isValid()
                          ? moment(item.dateFiled).format("MM/DD/YYYY")
                          : ""}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "7%" }}
                      >
                        {item.region}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "7%" }}
                      >
                        {item.facility}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "7%" }}
                      >
                        {item.step}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "8%" }}
                      >
                        {item.subject}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "10%" }}
                      >
                        {item.representativeName}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "8%" }}
                      >
                        {item.assignedGRTUserName}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "6%" }}
                      >
                        {item.assignedUserName}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "8%" }}
                      >
                        {item.articles &&
                          !_.isEmpty(item.articles) &&
                          item.articles.map((x) => (
                            <Tooltip text={x.articleName}>
                              <span
                                className="badge badge-pill c-pointer badge-success"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  e.nativeEvent.stopImmediatePropagation();
                                }}
                              >
                                Article - {x.articleIndex}{" "}
                              </span>
                            </Tooltip>
                          ))}
                      </div>
                      {(() => {
                        if (!item.remainingDays) {
                          return (
                            <div
                              className="px-3 py-2 border-left border-right"
                              style={{ width: "6%" }}
                            >
                              No Record
                            </div>
                          );
                        }
                        if (item.remainingDays < 5) {
                          return (
                            <div
                              className="px-3 py-2 border-left border-right"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                width: "6%",
                              }}
                            >
                              {item.remainingDays} Days
                            </div>
                          );
                        } else if (item.remainingDays < 10) {
                          return (
                            <div
                              className="px-3 py-2 border-left border-right bg-warning text-light"
                              style={{ width: "6%" }}
                            >
                              {item.remainingDays} Days
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="px-3 py-2 border-left border-right"
                              style={{ width: "6%" }}
                            >
                              {item.remainingDays} Days
                            </div>
                          );
                        }
                      })()}

                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "8%" }}
                      >
                        {item.gId}
                      </div>

                      <div
                        className="px-3 py-2 border-left"
                        style={{ width: "5%" }}
                      >
                        {this.state.show ? (item.pdfData ? (
                          <PDFDownloadLink
                            document={<PdfDocument data={item.pdfData} />}
                            fileName={item.gId + ".pdf"}
                            onClick={(e) => {
                              e.preventDefault();
                              e.nativeEvent.stopImmediatePropagation();
                            }}
                          >
                            <i class="fas fa-file-pdf" />
                          </PDFDownloadLink>) : ""
                        ) : (
                          <i className="fas fa-spinner"></i>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ))}
          </>
        );
      },
      onExpand: async (row, isExpand, rowIndex, e) => {
        await this.setState({ show: false });
        if (isExpand) {
          await this.setState(() => ({
            expanded: [...this.state.expanded, row.status],
          }));
        } else {
          await this.setState(() => ({
            expanded: this.state.expanded.filter((x) => x !== row.status),
          }));
        }
        setTimeout(() => {
          this.setState({ show: true });
        }, 2000);
      },
      expanded: this.state.expanded,
    };
  };

  //rowEvents = {
  //  onClick: (e, row, rowIndex) => {
  //    if (row.canEdit && e.target.className.indexOf("pdf") == -1) {
  //      this.props.history.push({
  //        pathname: "/grievances/" + row.grievanceId,
  //        type: "create",
  //        uid: row.id,
  //      });
  //    }
  //  },
  //};

  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    rowClasses
  ) {
    const customTotal = (from, to, size) => (
      <span
        className="react-bootstrap-table-pagination-total"
        style={{ float: "left", marginTop: "-1px", marginRight: "4px" }}
      >
        {from}
        {" - "}
        {to} of {size} {"   "}
        <b style={{ marginLeft: "4px" }}> Show: </b>
      </span>
    );
    const defaultPaginationConfigs = paginationFactory({
      paginationTotalRenderer: customTotal,
      onSizePerPageChange: this.SizePerPageChange,
      onPageChange: this.OnPageChange,
      sizePerPage: this.state.sizePerPage,
      page: this.state.page,
      totalSize: this.state.grievanceCount,
      showTotal: true,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "30",
          value: 30,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "All",
          value: this.state.grievanceCount,
        },
      ],
    });
    return (
      <div>
        <BootstrapTable
          remote
          //keyField={keyFields}
          keyField={"status"}
          data={data}
          columns={columns}
          onTableChange={this.handleTableChange}
          classes="expandable"
          pagination={defaultPaginationConfigs}
          rowClasses={rowClasses}
          expandRow={this.expandRow()}
        />
      </div>
    );
  }
  handleDelete(row, event) {
    Services.DeleteGrievance(row.grievanceId).then((data) => {
      if (!_.isEmpty(data.status)) {
        if (data.status == "Success") {
          toast.success(data.message);
          this.props.history.go(0);
        } else {
          toast.error(data.message);
        }
      }
    });
  }
  getColumn() {
    return isMobile
      ? [
        {
          dataField: "status",
          text: "Grievant",
          headerAttrs: {
            width: "25%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          onSort: async (field, order) => {
            this.state.grievancesData = _.orderBy(
              this.state.grievancesData,
              ["grievant"],
              [order]
            );
            await this.setState({
              grievancesData: this.state.grievancesData,
              filteredGrievances: this.arrangeDataWithStatus(
                this.state.grievancesData,
                this.state.sizePerPage,
                this.state.page
              ),
            });
          },
          classes: "px-3 py-2 border-right-0 bg-secondary text-white",
          formatter: null,
        },
        {
          text: "Date Filed",
          dataField: "dateFiled",
          headerAttrs: {
            width: "30%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 py-2 border-right-0 border-left-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
            //return (
            //  <div>
            //    {moment(row.dateFiled).isValid()
            //      ? moment(row.dateFiled).format("MM/DD/YYYY")
            //      : ""}
            //  </div>
            //);
          },
        },
        {
          dataField: "region",
          text: "Region",
          headerAttrs: {
            width: "22%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,

          classes:
            "px-3 py-2 border-right-0 border-left-0  bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },

        {
          dataField: "facility",
          text: "Facility",
          headerAttrs: {
            width: "23%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes: "px-3 py-2 border-left-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
      ]
      : [
        {
          dataField: "status",
          text: "Grievant",
          headerAttrs: {
            width: "10%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          onSort: async (field, order) => {
            this.state.grievancesData = _.orderBy(
              this.state.grievancesData,
              ["grievant"],
              [order]
            );
            await this.setState({
              grievancesData: this.state.grievancesData,
              filteredGrievances: this.arrangeDataWithStatus(
                this.state.grievancesData,
                this.state.sizePerPage,
                this.state.page
              ),
            });
          },
          classes: "px-3 py-2 border-right-0 bg-secondary text-white",
          formatter: null,
        },
        {
          dataField: "dateFiled",
          text: "Date Filed",
          headerAttrs: {
            width: "10%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
            //return (
            //  <div>
            //    {moment(row.dateFiled).isValid()
            //      ? moment(row.dateFiled).format("MM/DD/YYYY")
            //      : ""}
            //  </div>
            //);
          },
        },
        {
          dataField: "region",
          text: "Region",
          headerAttrs: {
            width: "7%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,

          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },

        {
          dataField: "facility",
          text: "Facility",
          headerAttrs: {
            width: "7%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },

        {
          dataField: "step",

          text: "Step",

          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          headerAttrs: {
            width: "7%",
          },
          sort: true,
          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "subject",

          text: "Subject",

          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          headerAttrs: {
            width: "8%",
          },
          sort: true,
          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          text: "Representative",
          headerAttrs: {
            width: "10%",
          },
          dataField: "representativeName",
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          text: "GRT Rep.",
          headerAttrs: {
            width: "8%",
          },
          dataField: "assignedGRTUserName",
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "assignedUserName",
          text: "LR Rep.",
          headerAttrs: {
            width: "6%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "articleName",
          text: "Contract Article",
          headerAttrs: {
            width: "8%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "remainingDays",
          text: "Remaining Days",
          headerAttrs: {
            width: "6%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
          /*formatter: (cellContent, row) => {
                if (!row.remainingDays) {
                  return <div>No Record</div>;
                }
                if (row.remainingDays < 0) {
                  return (
                    <div style={{ backgroundColor: "red", color: "white" }}>
                      {row.remainingDays} Days
                    </div>
                  );
                } else {
                  return <div>{row.remainingDays} Days</div>;
                }
              },*/
        },
        {
          dataField: "gId",
          text: "ID",
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          headerAttrs: {
            width: "8%",
          },
          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          text: "",
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          headerAttrs: {
            width: "5%",
          },
          sort: false,
          classes:
            "px-3 py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
      ];
  }

  SizePerPageChange = (sizePerPage) => {
    this.setState({
      sizePerPage: sizePerPage,
      page: 1,
      filteredGrievances: this.arrangeDataWithStatus(
        _.cloneDeep(this.state.grievancesData),
        sizePerPage,
        1
      ),
    });
  };
  OnPageChange = (page) => {
    $(".loading").show();
    this.state.filteredGrievances = this.arrangeDataWithStatus(
      _.cloneDeep(this.state.grievancesData),
      this.state.sizePerPage,
      page
    );
    this.setState({
      page: page,
      filteredGrievances: this.state.filteredGrievances,
    });
    $(".loading").hide();
  };
  viewAllLink = (data) => {
    this.setState({ sizePerPage: data.length });
  };
  filterMyRequest = async (searchTerm, searchType, obj) => {
    let sTerm = _.toLower(searchTerm);
    var searchStatus,
      searchkeyword,
      searchRegion,
      searchFacility,
      searchLR,
      searchArticle,
      searchGRT = "";
    let searchContract = this.state.searchTermContract;

    var currentGrievanceData = this.state.completeGrievancesList;
    switch (searchType) {
      case "keyword":
        this.state.searchkeyword = sTerm;
        searchkeyword = sTerm;
        break;
      case "status":
        this.state.searchTermStatus = sTerm;
        searchStatus = sTerm;
        break;
      case "region":
        this.state.searchTermRegion = sTerm;
        searchRegion = sTerm;
        break;
      case "lr":
        this.state.searchTermLR = sTerm;
        searchLR = sTerm;
        break;
      case "contract":
        {
          this.state.searchTermContract = sTerm;
          searchContract = sTerm;
          this.state.searchTermArticle = "";
          searchArticle = "";
          await this.setState({ searchTermArticle: this.state.searchTermArticle, searchTermArticle: this.state.searchTermArticle });
          await this.filterArticlesBasedOnContracts(searchContract);
          break;
        }

      case "article":
        this.state.searchTermArticle = sTerm;
        searchArticle = sTerm;
        break;
      case "grt":
        this.state.searchTermGRT = sTerm;
        searchGRT = sTerm;
        break;
      case "facility":
        this.state.searchTermFacility = sTerm;
        searchFacility = sTerm;
        break;
      default:
        break;
    }
    if (searchkeyword) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return _.toLower(item.subject).indexOf(sTerm) > -1;
      });
    }
    if (searchStatus) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return _.toLower(item.status).indexOf(sTerm) > -1;
      });
    }
    if (searchRegion) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return _.toLower(item.region).indexOf(sTerm) > -1;
      });
    }
    if (searchFacility) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return _.toLower(item.facility).indexOf(sTerm) > -1;
      });
    }
    if (searchLR) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return _.toLower(item.assignedUserName).indexOf(sTerm) > -1;
      });
    }
    if (searchGRT) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return _.toLower(item.assignedGRTUserName).indexOf(sTerm) > -1;
      });
    }
    if (searchArticle) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return (
          item.articles &&
          !_.isEmpty(item.articles) &&
          item.articles.some((article) => {
            return _.toLower(article.articleName).indexOf(sTerm) > -1;
          })
        );
      });

    }


    if (searchFacility) this.state.facility = obj;
    if (searchGRT) this.state.assignedGRT = obj;
    if (searchLR) this.state.assignedLR = obj;
    if (searchArticle) this.state.contractArticle = obj;
    //if (searchRegion) {
    //  var selectedRegion = _.filter(this.state.regions, function(item) {
    //    return _.toLower(item.region).indexOf(sTerm) > -1;
    //  });
    //  this.loadMembersByRegion(
    //    selectedRegion[0].regionId,
    //    sTerm,
    //    searchType,
    //    obj
    //  );
    //}
    this.setState({
      filteredGrievances: this.arrangeDataWithStatus(
        _.cloneDeep(currentGrievanceData),
        this.state.sizePerPage,
        1
      ),
      searchTermArticle: "", //this.state.searchTermArticle,
      grievancesData: currentGrievanceData,
      page: 1,
      grievanceCount: currentGrievanceData.length,
    });
    this.expandAll();
  };

  handleClearSearch() {
    $("#status").val("0");
    $("#region").val("0");
    this.setState({
      searchTerm: "",
      searchType: "",
      searchTermStatus: "",
      searchTermRegion: "",
      searchTermFacility: "",
      searchTermArticle: "",
      searchTermLR: "",
      searchkeyword: "",
      searchTermGRT: "",
      searchTermContract: "",
      contractArticles: [],
      isClean: true,
      facility: {},
      region: {},
      assignedGRT: {},
      assignedLR: {},
      showClearButton: false,
      facilities: this.state.facilities,
      filteredGrievances: this.arrangeDataWithStatus(
        this.state.completeGrievancesList,
        this.state.sizePerPage,
        1
      ),
      page: 1,
      grievancesData: this.state.completeGrievancesList,
      grievanceCount: this.state.completeGrievancesList.length,
    });
    this.loadArticles = this.loadArticles.bind(this);
  }

  openFilterModal() {
    this.setState({
      isSearchModalOpen: true,
    });
  }
  loadArticles() {
    let articlesList = [];
    authAxios.get("/api/Grievance/GetAllArticles").then(({ data }) => {
      if (!_.isEmpty(data.data)) {
        this.setState({
          articlesList: data.data,
        });

      }
    });
  }
  async filterArticlesBasedOnContracts(contractIdString) {
    let articlesList = [];
    const contractId = parseInt(contractIdString, 10);
    if (!_.isEmpty(this.state.articlesList)) {

      articlesList = this.state.articlesList.filter(el => el.contractId === contractId) // Filter the articles by contractId
        .map(el => ({
          articleId: el.id,        // Map to the required format
          articleName: "Article - " + el.articleIndex + ", " + el.articleName.trim() // Trim any extra spaces
        }));

    }
    await this.setState({
      contractArticles: articlesList,
      searchTermContract: contractId
    });
  }
  async loadAllContracts() {
    let contractList = [];
    let data = await Services.loadAllContracts();
    if (!_.isEmpty(data.data)) {
      _.forEach(data.data, function (el, index, arr) {
        contractList.push({
          id: el.id,
          contractName: el.contractName,
        });
      });
      this.setState({
        contractList: contractList,
      });
    }

  }

  loadMembersByRegion(regionId, searchTerm, searchType, region) {
    Services.GetLRRepresentatives(regionId).then((result) => {
      if (!_.isEmpty(result.data)) {
        debugger;
        var UserList = [];
        result.data.map((item) => {
          UserList.push({
            assignedUserName: item.firstname + " " + item.lastname,
          });
        });
        this.setState({
          assignedUserList: UserList,
          searchType: searchType,
          searchTerm: searchTerm,
          region: region,
        });
      }
    });
  }

  toggleSearchModel = () => {
    this.setState({ isSearchModalOpen: !this.state.isSearchModalOpen });
  };

  handleDropdownChange(dropdownType) {
    switch (dropdownType) {
      case "status":
        this.setState({
          searchType: dropdownType,
        });
      case "region":
        this.setState({
          searchType: dropdownType,
        });
      case "facility":
        this.setState({
          searchType: dropdownType,
        });
    }
    this.filterMyRequest();
  }

  render() {
    return (
      <AUX>
        <GrievanceHeader
          changeActiveTab={this.changeActiveTab}
          ActiveTab={this.state.ActiveTab}
        />
        <div className="pac-container container-fluid">
          <div className="row">
            <div className="col-md-12" style={{ paddingTop: "25px" }}>
              <div className="col-md-3 float-left">
                <form role="search" className="app-search mt-0">
                  <MDBInput
                    name="keyword"
                    size="lg"
                    hint="Search Subject"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={this.state.searchkeyword}
                    onChange={(e) => {
                      if (e.target.value) {
                        e.preventDefault();
                        this.filterMyRequest(
                          e.target.value,
                          "keyword",
                          e.target.value
                        );
                      } else {
                        this.handleClearSearch();
                      }
                    }}
                    placeholder="Search By Subject"
                    type="text"
                  />
                </form>
              </div>
              <div className="col-md-9 float-left">
                <button
                  type="button"
                  className="c-pointer mb-1 px-3 btn btn-sm btn-outline-success pl-2"
                  style={{
                    marginTop: "25px",
                    marginLeft: "35px",
                    height: "45px",
                  }}
                  onClick={() => {
                    this.openFilterModal(true);
                  }}
                >
                  <i class="cursorPointer pr-1 fab fa-searchengin" />
                  More Filters
                </button>
                <div className="float-left" />
              </div>
            </div>
          </div>
          <div className="page-title-box" />
          <div className="row" id="tableRow">
            <div className="col-12" style={{ overflow: "scroll" }}>
              {!_.isEmpty(this.state.filteredGrievances) ? (
                this.RemotePagination(
                  this.getColumn(),
                  "id",
                  this.state.filteredGrievances, //this.filterMyRequest(),
                  this.SizePerPageChange,
                  this.state.sizePerPage,
                  this.rowClasses
                )
              ) : this.state.loadState ? (
                <p className="mt-4 text-center">Loading... please wait.</p>
              ) : (
                <p className="mt-4 text-center">No Grievances Are Available</p>
              )}
            </div>
          </div>
          <GrievanceSearchModal
            modal={this.state.isSearchModalOpen}
            toggle={this.toggleSearchModel}
            statuses={this.state.statuses}
            facilities={this.state.facilities}
            articlesList={this.state.contractArticles}
            articleValue={this.state.contractArticle}
            regions={this.state.regions}
            searchTermArticle={this.state.searchTermArticle}
            assignedGRTUserList={this.state.assignedGRTUserList}
            contractList={this.state.contractList}
            searchTermContract={this.state.searchTermContract}
            assignedUserList={this.state.assignedUserList}
            filterMyRequest={this.filterMyRequest}
            facilityValue={this.state.facility}
            regionValue={this.state.region}
            grtValue={this.state.assignedGRT}
            grievanceCount={this.state.grievanceCount}
            lrValue={this.state.assignedLR}
            handleClearSearch={this.handleClearSearch}
          />
        </div>
      </AUX>
    );
  }
}
export default withRouter(
  connect((state) => ({ grievanceState: state.grievances }), {
    ...GrievanceAction,
  })(ReviewGrievances)
);
