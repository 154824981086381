import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import { MDBInput } from "mdbreact";
import { makeStyles } from "@material-ui/core/styles";
import "moment/locale/en-ca";
import DatePicker from "react-datepicker";
import GrievanceHeader from "../General/GrievanceHeader";
import GrievanceAction from "../../../store/grievance/action";
import _ from "lodash";
import "../grievances.css";
import { connect } from "react-redux";
import Services from "../GrievanceService";
import { debounce } from "throttle-debounce";
import AuthService from "../../Auth/AuthService";
import $ from "jquery";
import GrievanceArticleVoilatedModal from "./GrievanceArticleVoilatedModal";
import EditRecipient from "./EditRecipient";
const DraftKey = "CreateGrievanceForm";
const filter = createFilterOptions();
class GrievanceCreateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRecipientModal: false,
            EditRecipientId: 0,
            EditRecipientName: null,
            step1Active: true,
            disableStep1: false,
            unionGrievance: false,
            ActiveTab: 1,
            isRepresentative: false,
            isGrievantDisabled: false,
            showContractInfo: false,
            isConfirmModalOpen: false,
            contractArticleDetailsData: "",
            contractArticleDetailsHeader: "",
            requestId: props.match.params.Id > 0 ? props.match.params.Id : 0,
            grievantsData: [],
            lrRepresentativeData: [],
            grtRepresentativeData: [],
            memberFacility: "",
            allContracts: [],
            facilities: [],
            facility: "",
            contractList: [],
            articlesList: [],
            completeArticlesList: [],
            isSpecialTeamMember: false,
            facRep: {},
            contractObj: null,

            grievantsSearchObj: {
                SearchTerms: "",
                Offset: 0,
                Fetch: 10,
                Data: [],
                IsMore: true,
                FetchedFirst: true,
            },
            facilitySearchObj: {
                SearchTerms: "",
                Offset: 0,
                Fetch: 10,
                Data: [],
                IsMore: true,
                FetchedFirst: true,
            },
            representativesList: [],
            recipientsList: [],
            articlesList: [],
            localContractsSelection: [],
            requestObject: {
                grievant: null,
                representative: "",
                recipient: "",
                contract: "",
                currentStep: "Step 1",
                isAdverse: false,
                isMeeting: true,
                dateOfVoilation: moment(new Date()).format("YYYY-MM-DD"),
                dateSubmitted: moment(new Date()).format("YYYY-MM-DD"),
                meetingDate: "",
                subject: "",
                FAANumber: "",
                statementOfFacts: "",
                fAANumber: "",
                remedySought:
                    "To be made whole in every way, including but not limited to _, and any other remedy deemed appropriate.",
                grievant_err: "",
                representative_err: "",
                recipient_err: "",
                dateOfVoilation_err: "",
                dateSubmitted_err: "",
                contract_err: "",
                statementOfFacts_err: "",
                subject_err: "",
                remedySought_err: "",
                facility_err: "",
                files: [],
                filesTable: [],
            },
            showFileEditModal: false,
            editOriginalFileName: "",
            editFileName: "",
            editReference: {},
        };

        this.stepsList = [
            { id: 1, name: "Step 1" },
            { id: 2, name: "Step 1 Meeting" },
            { id: 3, name: "Step 1 Response" },
            { id: 4, name: "Step 2" },
            { id: 5, name: "Step 2 Meeting" },
            { id: 6, name: "Step 2 Response" },
        ];
        this.updateFiles = (fileItems) => {
            var property = { ...this.state.requestObject };
            property.files = fileItems
                .filter((el) => {
                    return el.status !== 8;
                })
                .map((fileItem) => {
                    var existing = property.filesTable.filter((i) => {
                        return i.originalfilename === fileItem.file.name;
                    });
                    var existingInFP = property.files.filter((i) => {
                        return i.name === fileItem.file.name;
                    });
                    if (_.isEmpty(existing)) {
                        property.filesTable.push({
                            filename: fileItem.file.name,
                            originalfilename: fileItem.file.name,
                            uploaded: Date.now(),
                            reference: property.currentStep,
                        });
                    }
                    return fileItem.file;
                });
            this.setState({
                requestObject: property,
            });
            this.saveToDraft(property);
        };

        this.deleteFile = (originalName) => {
            var property = { ...this.state.requestObject };
            var newFileArray = property.files.filter((item) => {
                return item.name !== originalName;
            });
            var newFileTableArray = property.filesTable.filter((item) => {
                return item.originalfilename !== originalName;
            });
            property.files = newFileArray;
            property.filesTable = newFileTableArray;
            this.setState({
                requestObject: property,
            });
            this.saveToDraft(property);
        };
        this.submitComment = (comment) => {
            var property = { ...this.state.requestObject };
            property.commentsData.push({
                actionDate: "10/10/2021",
                memberName: "Jason Doss",
                comments: comment,
            });
            this.setState({ requestObject: property });
            this.saveToDraft(property);
        };
        this.updateFileData = (dataObj) => {
            var property = { ...this.state.requestObject };
            var filesdata = property.filesTable.map((i) => {
                if (i.originalfilename === dataObj.originalfilename) {
                    i.filename = dataObj.filename;
                    i.reference = dataObj.reference.name;
                }
                return i;
            });
            property.filesTable = filesdata;
            this.setState({
                requestObject: property,
                editReference: dataObj.reference,
            });
            this.saveToDraft(property);
        };
        this.openInformationModal = (articleId) => {
            $(".loading").show();
            Services.GetArticleDetails(articleId).then((result) => {
                var articleArray = result.data.articleDetails;
                var html = "";
                for (let index = 0; index < articleArray.length; index++) {
                    const element = articleArray[index];
                    html =
                        html +
                        " <div><b>" +
                        element.sectionName +
                        "</b> <div>" +
                        element.sectionContent +
                        "</div> </div> <br />";
                }
                //html.replace('<ol type="a">', '<ol type="a" style="margin-left:50px;">');
                this.setState({
                    isConfirmModalOpen: !this.state.isConfirmModalOpen,
                    contractArticleDetailsData: html,
                    contractArticleDetailsHeader: result.data.articleName,
                });
                $(".loading").hide();
            });
        };
        this.updateChange = async (selectedObj, type, showInformation) => {
            let property = { ...this.state.requestObject };
            switch (type) {
                case "Grievant":
                    property.grievant = selectedObj;
                    property.grievant_err = "";

                    if (selectedObj) this.loadDataByGrievantId(selectedObj.id);
                    if (!this.props.grievanceState.isRepresentative)
                        this.setState({
                            isGrievantDisabled: true,
                            requestObject: property,
                        });
                    else this.setState({ requestObject: property });

                    break;

                case "Representative":
                    property.representative = selectedObj;
                    property.representative_err = "";
                    await this.setState({ requestObject: property });
                    this.getRecipientByRepresentativeId();
                    break;
                case "Facility":
                    this.setState({
                        facility: selectedObj,
                    });
                    break;
                case "Recipient":
                    property.recipient = selectedObj;
                    property.recipient_err = "";
                    this.setState({ requestObject: property });
                    break;

                case "Contract":
                    if (selectedObj) {
                        const existingItem = this.state.localContractsSelection.find(
                            (item) => item.id === selectedObj.id
                        );

                        if (!existingItem) {

                            const updatedSelection = [
                                ...this.state.localContractsSelection,
                                selectedObj,
                            ];
                            property.articles = updatedSelection;
                            this.setState({ localContractsSelection: updatedSelection });


                        }
                        property.contract = {};
                        property.contract_err = "";
                        if (showInformation)
                            this.setState({
                                requestObject: property,
                                showContractInfo: true,
                            });
                        else
                            this.setState({
                                requestObject: property,
                                showContractInfo: false,
                            });
                    }
                    break;
                case "Bu_Contracts":
                    {
                        this.state.contractObj = selectedObj;
                        await this.setState({ contractObj: this.state.contractObj });
                        await this.filterArticlesBasedOnContracts(selectedObj.id);
                        break;
                    }
                case "FAA":
                    property.FAANumber = selectedObj;
                    property.FAANumber_err = "";
                    this.setState({ requestObject: property });
                    break;
                case "Adverse":
                    property.isAdverse = !property.isAdverse;
                    var step = property.isAdverse ? false : true;
                    this.setState({ requestObject: property, step1Active: step });
                    break;
                case "Meeting":
                    property.isMeeting = !property.isMeeting;
                    this.setState({ requestObject: property });
                    break;
                case "DOV":
                    property.dateOfVoilation = selectedObj;
                    property.dateSubmitted_err = "";
                    this.setState({ requestObject: property });
                    break;
                case "DateSubmit":
                    property.dateSubmitted = selectedObj;
                    property.dateSubmitted_err = "";
                    this.setState({ requestObject: property });
                    break;
                case "Subject":
                    property.subject = selectedObj;
                    property.subject_err = "";
                    this.setState({ requestObject: property });
                    break;
                case "SOF":
                    property.statementOfFacts = selectedObj.target.value;
                    property.statementOfFacts_err = "";
                    this.setState({ requestObject: property });
                    break;
                case "Remedy":
                    property.remedySought = selectedObj.target.value;
                    property.remedySought_err = "";
                    this.setState({ requestObject: property });
                    break;
                default:
                    break;
            }
            this.saveToDraft(property);
        };

        this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
        this.customValidation = this.customValidation.bind(this);
        this.changeActiveTab = this.changeActiveTab.bind(this);
        this.grievantSearchHandler = this.grievantSearchHandler.bind(this);
        this.facilitySearchHandler = this.facilitySearchHandler.bind(this);
        this.loadGrievantsData = this.loadGrievantsData.bind(this);
        this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
        this.deleteRecipient = this.deleteRecipient.bind(this);
        this.loadAllContracts = this.loadAllContracts.bind(this);
        this.filterArticlesBasedOnContracts = this.filterArticlesBasedOnContracts.bind(this);
        this.loadArticles = this.loadArticles.bind(this);
        this.getRecipientByRepresentativeId = this.getRecipientByRepresentativeId.bind(
            this
        );
        this.closeRecipientModal = this.closeRecipientModal.bind(this);
        this.editRecipient = this.editRecipient.bind(this);
    }
    async loadArticles() {
        let articlesList = [];
        let data = await Services.loadAllArticles();
        if (!_.isEmpty(data.data)) {
            this.setState({
                completeArticlesList: data.data,
            });

        }

    }
    async filterArticlesBasedOnContracts(contractIdString) {
        let articlesList = [];
        const contractId = parseInt(contractIdString, 10);
        if (!_.isEmpty(this.state.completeArticlesList)) {

            articlesList = this.state.completeArticlesList.filter(el => el.contractId === contractId).map(el => ({
                id: el.id,        // Map to the required format
                name: "Article - " + el.articleIndex + ", " + el.articleName.trim(), // Trim any extra spaces
                contractId: el.contractId
            }));

        }
        await this.setState({
            articlesList: articlesList,
        });
    }
    async loadAllContracts() {
        let contractList = [];
        let data = await Services.loadAllContracts();
        if (!_.isEmpty(data.data)) {
            _.forEach(data.data, function (el, index, arr) {
                contractList.push({
                    id: el.id,
                    contractName: el.contractName,
                });
            });
            this.setState({
                contractList: contractList,
            });
        }

    }
    removeLocalArticleItem = (id) => {
        debugger;
        const updatedSelection = this.state.localContractsSelection.filter(
            (item) => item.id !== id
        );
        this.setState({ localContractsSelection: updatedSelection });
    };

    saveToDraft(Obj) {
        localStorage.setItem(DraftKey, JSON.stringify(Obj));
    }
    removeFromDraft(Obj) {
        localStorage.removeItem(DraftKey);
    }
    changeActiveTab(value) {
        this.setState({ ActiveTab: value });
    }
    autocompleteSearch = () => {
        this._fetch();
    };
    _fetch = async () => {
        var data = await this.loadGrievantsData();
    };
    openFileEditModal = (originalName, name, reference) => {
        var refObj = this.stepsList.filter((i) => i.name === reference);
        this.setState({
            editOriginalFileName: originalName,
            editFileName: name,
            editReference: refObj[0],
            showFileEditModal: true,
        });
    };
    grievantSearchHandler = (SearchTerms) => {
        let property = { ...this.state.grievantsSearchObj };
        property.SearchTerms = SearchTerms;
        this.setState({ grievantsSearchObj: property }, () => {
            this.autocompleteSearchDebounced();
        });
    };
    facilitySearchHandler = (SearchTerms) => {
        let property = { ...this.state.facilitySearchObj };
        property.SearchTerms = SearchTerms;
        this.setState({ facilitySearchObj: property }, () => {
            this.autocompleteSearchDebounced();
        });
    };
    loadGrievantsData = async () => {
        let grievantsSearchObj = this.state.grievantsSearchObj;
        let data = await Services.LoadGrievants(
            grievantsSearchObj.SearchTerms,
            grievantsSearchObj.Offset,
            grievantsSearchObj.Fetch
        );
        if (data.result && !_.isEmpty(data)) {
            if (data.statusCode == 200) {
                this.state.grievantsData = data.result;

                await this.setState({
                    grievantsData: data.result,
                });
            }
        }
    };

  loadDataByGrievantId = async (id, change = true) => {
    let result = await Services.LoadDataByGrievant(id);
    let representativesList = [];
    let articlesList = [];
    if (!_.isEmpty(result.data)) {
      _.forEach(result.data.representativesList, function(el, index, arr) {
        representativesList.push({
          id: el.id,
          name: el.firstname + " " + el.lastname,
        });
      });
      _.forEach(result.data.articlesList, function(el, index, arr) {
        articlesList.push({
          id: el.id,
          contractId: el.contractId,
          name: "Article " + el.articleIndex + " - " + el.articleName,
        });
      });
      let property = { ...this.state.requestObject };
      //   property.representative = _.head(representativesList);
      //const selectedRepresentative = result.data.representative;
      //const selectedarticle = result.data.article;
      property.contract = "";
      //property.contract = selectedarticle
      //  ? {
      //      id: selectedarticle.id,
      //      name:
      //        "Article " +
      //        selectedarticle.articleIndex +
      //        " - " +
      //        selectedarticle.articleName,
      //    }
      //  : "";
      property.representative = "";
      //property.representative = selectedRepresentative
      //  ? {
      //      name:
      //        selectedRepresentative.firstname +
      //        " " +
      //        selectedRepresentative.lastname,
      //      id: selectedRepresentative.id,
      //    }
      //  : "";
      property.recipient = "";
      //property.recipient = result.data.recipient
      //  ? {
      //      id: result.data.recipient.id,
      //      name: result.data.recipient.recipientName,
      //    }
        //  : {};
        if (change == true) {


            await this.setState({
                representativesList: representativesList,
                articlesList: articlesList,
                requestObject: property,
            });
            this.saveToDraft(property);
        }
      this.getRecipientByRepresentativeId();
    }
  };
  closeFileEditModal = () => {
    this.setState({
      editOriginalFileName: "",
      editFileName: "",
      editReference: {},
      showFileEditModal: false,
    });
  };
  customValidation() {
    var isValid = true;
    var property = { ...this.state.requestObject };

        if (
            !_.isEmpty(property.grievant) &&
            property.grievant &&
            property.grievant != null &&
            property.grievant != undefined
        ) {
            property.grievant_err = "";
        } else {
            isValid = false;
            property.grievant_err = "Please select Grievant.";
        }
        if (_.isEmpty(property.subject)) {
            isValid = false;
            property.subject_err = "Please enter Subject.";
        } else {
            property.subject_err = "";
        }
        // if (_.isEmpty(property.representative)) {
        //   isValid = false;
        //   property.representative_err = "Please select Representative.";
        // }
        if (_.isEmpty(property.recipient)) {
            isValid = false;
            property.recipient_err = "Please select Recipient.";
        } else {
            property.recipient_err = "";
        }
        if (_.isEmpty(property.dateOfVoilation)) {
            isValid = false;
            property.dateOfVoilation_err = "Please select Date Of Voilation.";
        } else {
            property.dateOfVoilation_err = "";
        }
        if (_.isEmpty(property.dateSubmitted)) {
            isValid = false;
            property.dateSubmitted_err = "Please select Date Submitted.";
        } else {
            property.dateSubmitted_err = "";
        }
        if (this.state.localContractsSelection.length == 0) {
            isValid = false;
            property.contract_err = "Please select Contract.";
        } else {
            property.contract_err = "";
        }
        if (_.isEmpty(property.statementOfFacts)) {
            isValid = false;
            property.statementOfFacts_err = "Please enter Statement Of Facts.";
        } else {
            property.statementOfFacts_err = "";
        }
        if (_.isEmpty(property.remedySought)) {
            isValid = false;
            property.remedySought_err = "Please enter Remedy Sought.";
        } else {
            property.remedySought_err = "";
        }
        if (!isValid) {
            this.setState({ requestObject: property });
            this.saveToDraft(property);
        }

        return isValid;
    }
    toggleConfirmModal = () => {
        this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
    };
    submitGrievance = async (isDraft, isStep1, submitDirectExpArb) => {
        $(".loading").show();
        var contracts = this.state.localContractsSelection;
        const contractIds = contracts.map((obj) => obj.id);
        let grievanceObject = {
            stepId: this.state.requestObject.stepId
                ? this.state.requestObject.stepId
                : null,
            id: this.state.requestObject.id ? this.state.requestObject.id : null,
            grievantId: this.state.requestObject.grievant.id,

            recipientName: this.state.requestObject.recipient.name,

            articleId: this.state.requestObject.contract.id,
            isAdverseAction: this.state.requestObject.isAdverse,
            status: isDraft ? 1 : 2,

            currentStepId: isStep1 == true ? 1 : 4,
            StepData: {
                stepName: submitDirectExpArb
                    ? "Expedited Arbitration"
                    : isStep1
                        ? "Step 1"
                        : "Step 2",
                fAANumber: "",
                dateOfVoilation: this.state.requestObject.dateOfVoilation,
                representativeId: this.state.requestObject.representative.id,
                recipientId: this.state.requestObject.recipient
                    ? this.state.requestObject.recipient.id
                    : 0,
                dateSubmitted: this.state.requestObject.dateSubmitted,
                subject: this.state.requestObject.subject,
                contractIds: contractIds,
                statementOfFacts: this.state.requestObject.statementOfFacts,
                remedySought: this.state.requestObject.remedySought,
                isMeetingRequired: this.state.requestObject.isMeeting,
            },
        };

        if (!isDraft) {
            if (this.customValidation()) {
                var formdata = new FormData();
                formdata.append("data", JSON.stringify(grievanceObject));
                Services.SaveGrievance(formdata).then(() => {
                    this.removeFromDraft();
                    this.props.history.push({
                        pathname: "/Grievances",
                        type: "list",
                        toastMsg: "Grievance has been created successfuly.",
                    });
                });
            } else {
                $(".loading").hide();
            }
        } else {
            var formdata = new FormData();
            formdata.append("data", JSON.stringify(grievanceObject));
            Services.SaveGrievance(formdata).then(() => {
                $(".loading").hide();
                this.removeFromDraft();
                this.props.history.push({
                    pathname: "/Grievances",
                    type: "list",
                    toastMsg: "Grievance has been created successfuly.",
                });
            });
        }
    };
    loadGrievanceDetails = async (id) => {
        $(".loading").show();
        await Services.LoadDataByGrievant(id).then((result) => {
            let representativesList = [];
            let recipientsList = [];
            let articlesList = [];
            if (!_.isEmpty(result.data)) {
                _.forEach(result.data.representativesList, function (el, index, arr) {
                    representativesList.push({
                        id: el.id,
                        name: el.firstname + " " + el.lastname,
                    });
                });
                _.forEach(result.data.recipientsList, function (el, index, arr) {
                    recipientsList.push({
                        id: el.id,
                        name: el.recipientName,
                    });
                });

                _.forEach(result.data.articlesList, function (el, index, arr) {
                    articlesList.push({
                        id: el.id,
                        name: el.articleName,

                    });
                });

                let property = { ...this.state.requestObject };
                property.representative = _.head(representativesList);

                this.setState({
                    representativesList: representativesList,
                    recipientsList: recipientsList,
                    articlesList: articlesList,
                    requestObject: property,
                });
                this.saveToDraft(property);
                $(".loading").hide();
            }
        });
    };
    async getRecipientByRepresentativeId() {
        if (
            this.state.requestObject.representative != null &&
            this.state.requestObject.representative.id
        ) {
            let recipientsList = [];
            let result = await Services.GetRecipient(
                this.state.requestObject.representative.id
            );
            _.forEach(result, function (el, index, arr) {
                recipientsList.push({
                    id: el.id,
                    name: el.recipientName,
                });
            });
            this.state.recipientsList = recipientsList;
        } else {
            this.state.recipientsList = [];
        }

        await this.setState({
            recipientsList: this.state.recipientsList,
            requestObject: this.state.requestObject,
        });
        this.saveToDraft(this.state.requestObject);
    }
    deleteRecipient = async (id) => {
        $(".loading").show();
        let val = await Services.DeleteRecipient(id);
        if (!_.isEmpty(this.state.recipientsList)) {
            this.getRecipientByRepresentativeId();
        }
        $(".loading").hide();
    };
    editRecipient = async (e, id, name) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        await this.setState({
            EditRecipientId: id,
            EditRecipientName: name,
            showRecipientModal: true,
        });
    };
    closeRecipientModal = () => {
        this.setState({ showRecipientModal: false });
    };
    componentDidMount() {
        var facilitiesWithNatca = [];
        Services.GetFacilitiesWithNATCA().then((response) => {
            debugger;
            facilitiesWithNatca = response.facilitiesModel.map((item) => {
                return {
                    id: item.id,
                    name: item.code,
                };
            });
            var memberContracts = [];

      _.forEach(response.memberContractArticles, function(el, index, arr) {
        memberContracts.push({
          id: el.id,
          contractId: el.contractId,
          name: "Article " + el.articleIndex + " - " + el.articleName,
        });
      });
      if (!_.isEmpty(response.representative)) {
        this.setState({
          facRep: {
            id: response.representative.id,
            name:
              response.representative.firstname +
              " " +
              response.representative.lastname,
          },
          isSpecialTeamMember: response.isSpecialTeamMember,
          memberFacility: response.facilityId,
          facilities: facilitiesWithNatca,
          articlesList: memberContracts,
        });
      }
    });
    if (localStorage.getItem(DraftKey) == null) {
      this.saveToDraft(this.state.requestObject);
    } else {
      this.state.requestObject = JSON.parse(localStorage.getItem(DraftKey));
      if (
        this.state.requestObject &&
        this.state.requestObject.articles &&
        !_.isEmpty(this.state.requestObject.articles) &&
        this.state.requestObject.articles.length > 0
      ) {
        this.state.localContractsSelection = this.state.requestObject.articles;
      }
      this.setState({
        requestObject: this.state.requestObject,
        localContractsSelection: this.state.localContractsSelection,
        facilities: facilitiesWithNatca,
      });

      if (
        this.state.requestObject &&
        this.state.requestObject.grievant &&
        this.state.requestObject.grievant != null &&
        this.state.requestObject.grievant.id > 0
      ) {
        this.loadDataByGrievantId(this.state.requestObject.grievant.id,false);
      }
    }

    Services.GetRepresentativeRole().then((response) => {
      const user = AuthService.getProfile().memberData;
      var username = user.firstname + " " + user.lastname;
      let selectedRepresentative = {};
      if (response.data) {
        this.setState({
          isGrievantDisabled: false,
        });
        this.loadGrievantsData();
      } else {
        Services.LoadGrievants(username, null, null).then((result) => {
          if (!_.isEmpty(result.result)) {
            const selectedUser = result.result[0];
            this.updateChange(selectedUser, "Grievant");
          }
        });
      }
      Services.GetLRRepresentatives(0).then((result) => {
        if (!_.isEmpty(result.data)) {
          var LRMembers = [];
          result.data.map((item) => {
            LRMembers.push({
              id: item.id,
              name: item.firstname + " " + item.lastname,
            });
          });
          this.setState({ lrRepresentativeData: LRMembers });
        }
      });
      Services.GetGRTRepresentatives().then((result) => {
        if (!_.isEmpty(result.data)) {
          var GRTMembers = [];
          result.data.map((item) => {
            GRTMembers.push({
              id: item.id,
              name: item.firstname + " " + item.lastname,
            });
          });
          this.setState({ grtRepresentativeData: GRTMembers });
        }
      });
      //this.loadGrievantsData();
    });
  }
  render() {
    let classes = makeStyles({
      option: {
        fontSize: 15,
        "& > span": {
          marginRight: 10,
          fontSize: 18,
        },
      },
    });
    const tooltipStyle = {
      display: this.state.hover ? "block" : "none",
    };
    return (
      <div
        className="pac-container container-fluid"
        style={{ marginBottom: "80px" }}
      >
        <div className="row pt-3">
          <div className="col-lg-8 px-3 py-0">
            <div>
              <GrievanceHeader
                changeActiveTab={this.changeActiveTab}
                ActiveTab={this.state.ActiveTab}
              />
              <div className="col-lg-12 cardHeaderMainDiv p-0 mb-2">
                <div>
                  <div className="cardHeader">
                    <a className="font-italic">Create New Grievance</a>
                  </div>
                  <div className="cardBody">
                    <div className="row m-0 my-3">
                      <div className="col-md-12 mt-3 mb-2">
                        <button
                          onClick={() => {
                            let property = { ...this.state.requestObject };
                            property.grievant = null;
                            property.representative = "";

                            this.setState({
                              unionGrievance: false,
                              step1Active: true,
                              disableStep1: false,
                              isGrievantDisabled: false,
                              requestObject: property,
                            });
                          }}
                          className={
                            "btn btn-sm w-md waves-effect waves-light stepBtn step1Btn " +
                            (this.state.unionGrievance ? "" : "activeTab")
                          }
                          type="button"
                          id="Step1btn"
                        >
                          Employee
                        </button>
                        <button
                          className={
                            "btn btn-sm w-md waves-effect waves-light stepBtn step2Btn " +
                            (this.state.unionGrievance ? "activeTab" : "")
                          }
                          type="button"
                          onClick={() => {
                            var memberFacilitid = this.state.memberFacility;
                            const currentMemberFacility = this.state.facilities.find(
                              (item) => item.id === memberFacilitid
                            );
                            if (_.isEmpty(this.state.completeArticlesList)) {
                                this.loadArticles();
                            }
                            if (_.isEmpty(this.state.contractList)) {
                                this.loadAllContracts();
                            }

                            let property = { ...this.state.requestObject };
                            property.representative = this.state.facRep;
                            property.grievant = {
                              id: 0,
                              name: "NATCA",
                            };
                            this.setState({
                              unionGrievance: true,
                              step1Active: false,
                              disableStep1: true,
                              facility: currentMemberFacility,
                              requestObject: property,
                              isGrievantDisabled: true,
                            });
                          }}
                          id="Step2btn"
                        >
                          Union
                        </button>
                      </div>
                    </div>
                    <div className="row m-0 my-3">
                      <div className="col-md-12 mt-3 mb-2">
                        <Autocomplete
                          options={this.state.grievantsData}
                          filterOptions={(options, state) => options}
                          classes={{
                            option: classes.option,
                          }}
                          autoComplete={true}
                          autoHighlight={true}
                          onInputChange={(e, value, reason) => {
                            this.grievantSearchHandler(value);
                          }}
                          value={this.state.requestObject.grievant}
                          disabled={this.state.isGrievantDisabled}
                          onChange={(e, value, reason) => {
                            debugger;
                            this.updateChange(value, "Grievant");
                          }}
                          getOptionLabel={(option) => {
                            if (option != undefined) {
                              return option.name + " - " + option.memberType;
                            } else {
                              return "";
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                className:
                                  "pb-1" +
                                  (params.inputProps &&
                                    " " + params.inputProps.className),
                              }}
                              variant="outlined"
                              label="Grievant"
                              InputLabelProps={{
                                shrink: true,
                                classes: { shrink: "mt-n1" },
                              }}
                              placeholder="Select BUE..."
                              fullWidth
                            />
                          )}
                        />
                        <span className="text-danger">
                          {this.state.requestObject.grievant_err}
                        </span>
                      </div>

                      {this.state.unionGrievance && (
                        <div className="col-md-12 mt-3 mb-2">
                          <Autocomplete
                            options={this.state.facilities}
                            classes={{
                              option: classes.option,
                            }}
                            autoComplete={true}
                            autoHighlight={true}
                            disabled={!this.state.isSpecialTeamMember}
                            value={this.state.facility}
                            onChange={(e, value, reason) => {
                              this.updateChange(value, "Facility");
                            }}
                            getOptionLabel={(option) => {
                              if (option != undefined) {
                                return option.name;
                              } else {
                                return "";
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "disabled", // disable autocomplete and autofill
                                  className:
                                    "pb-1" +
                                    (params.inputProps &&
                                      " " + params.inputProps.className),
                                }}
                                variant="outlined"
                                label="Facility"
                                InputLabelProps={{
                                  shrink: true,
                                  classes: { shrink: "mt-n1" },
                                }}
                                placeholder="Select Facility..."
                                fullWidth
                              />
                            )}
                          />
                          <span className="text-danger">
                            {this.state.requestObject.facility_err}
                          </span>
                        </div>
                      )}
                    </div>

                                        <div className="row m-0 my-3">
                                            <div className="col-md-6 mb-2">
                                                <div className="py-1">
                                                    <span className="">Adverse Action ?</span>
                                                    <span
                                                        style={{
                                                            cursor: "help",
                                                            marginLeft: "5px",
                                                            fontSize: "16px",
                                                        }}
                                                        title="Disciplinary action includes suspensions of 14 days or less and reprimands, while adverse action includes the more severe forms of discipline such as removals, suspensions of more than 14 days, and a reduction in grade (demotion) or pay."
                                                    >
                                                        <i class="fas fa-info-circle" />
                                                    </span>
                                                    <div class="input-group mb-3">
                                                        <input
                                                            type="checkbox"
                                                            switch="bool"
                                                            checked={this.state.requestObject.isAdverse}
                                                            onChange={() =>
                                                                this.updateChange(null, "Adverse")
                                                            }
                                                            id={"isAdverseAction"}
                                                            name={"isAdverseAction"}
                                                        />
                                                        <label
                                                            className="mb-n2"
                                                            htmlFor={"isAdverseAction"}
                                                            data-on-label="Yes"
                                                            data-off-label="No"
                                                        />
                                                        {this.state.requestObject.isAdverse && (
                                                            <div
                                                                class="alert alert-info w-100 mt-4 text-bold"
                                                                role="alert"
                                                            >
                                                                Notify RVP/ARVP of Pending Adverse Action
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <div className="py-1">
                                                    <span className="">Meeting Requested ?</span>
                                                    <div class="input-group mb-3">
                                                        <input
                                                            type="checkbox"
                                                            switch="bool"
                                                            checked={this.state.requestObject.isMeeting}
                                                            onChange={() =>
                                                                this.updateChange(null, "Meeting")
                                                            }
                                                            id={"isMeetingRequested"}
                                                            name={"isMeetingRequested"}
                                                        />
                                                        <label
                                                            className="mb-n2"
                                                            htmlFor={"isMeetingRequested"}
                                                            data-on-label="Yes"
                                                            data-off-label="No"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="px-2">
                                            <h6 className="mt-0 text-uppercase">
                                                Grievance Details
                                                <hr className="mt-2" />
                                            </h6>
                                        </div>
                                        <div className="row m-0 my-3">
                                            <div className="col-12">
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.disableStep1) {
                                                            this.setState({ step1Active: true });
                                                        }
                                                    }}
                                                    className={
                                                        "btn btn-sm w-md waves-effect waves-light stepBtn step1Btn " +
                                                        (this.state.step1Active ? "activeTab" : "") +
                                                        (this.state.disableStep1 ? "disabled" : "")
                                                    }
                                                    type="button"
                                                    id="Step1btn"
                                                >
                                                    Step 1
                                                </button>
                                                <button
                                                    className={
                                                        "btn btn-sm w-md waves-effect waves-light stepBtn step2Btn " +
                                                        (this.state.step1Active ? "" : "activeTab")
                                                    }
                                                    type="button"
                                                    onClick={() => {
                                                        this.setState({ step1Active: false });
                                                    }}
                                                    id="Step2btn"
                                                >
                                                    Step 2
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row m-0 my-3">
                                            <div className="col-md-6 mb-2">
                                                <Autocomplete
                                                    options={this.state.representativesList}
                                                    classes={{
                                                        option: classes.option,
                                                    }}
                                                    autoComplete={true}
                                                    autoHighlight={true}
                                                    onChange={(event, value) =>
                                                        this.updateChange(value, "Representative")
                                                    }
                                                    value={this.state.requestObject.representative}
                                                    getOptionLabel={(option) => {
                                                        if (option != undefined) {
                                                            return option.name;
                                                        } else {
                                                            return "";
                                                        }
                                                    }}
                                                    renderOption={(option) => (
                                                        <React.Fragment>{option.name}</React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Representative"
                                                            placeholder="Select Representative..."
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: { shrink: "mt-n1" },
                                                            }}
                                                            fullWidth
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "disabled", // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <span className="text-danger">
                                                    {this.state.requestObject.representative_err}
                                                </span>
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <Autocomplete
                                                    options={this.state.recipientsList}
                                                    classes={{
                                                        option: classes.option,
                                                    }}
                                                    //autoComplete={true}
                                                    //autoHighlight={true}
                                                    onChange={(event, value) =>
                                                        this.updateChange(value, "Recipient")
                                                    }
                                                    filterOptions={(options, params) => {
                                                        const filtered = filter(options, params);

                                                        // Suggest the creation of a new value
                                                        if (params.inputValue !== "") {
                                                            filtered.push({
                                                                id: 0,
                                                                name: `${params.inputValue}`,
                                                            });
                                                        }

                                                        return filtered;
                                                    }}
                                                    value={this.state.requestObject.recipient}
                                                    getOptionLabel={(option) => {
                                                        if (option != undefined) {
                                                            return option.name;
                                                        } else {
                                                            return "";
                                                        }
                                                    }}
                                                    renderOption={(option) => {
                                                        var exist = true;
                                                        if (
                                                            !_.find(this.state.recipientsList, {
                                                                id: option.id,
                                                            })
                                                        ) {
                                                            exist = false;
                                                        }
                                                        if (exist) {
                                                            return (
                                                                <li className="w-100">
                                                                    {" "}
                                                                    <div className="row mx-0">
                                                                        <div className="col-9">{option.name}</div>
                                                                        <div className="col-3 text-right">
                                                                            {" "}
                                                                            <i
                                                                                className="text-secondary pr-2 fas fa-pencil-alt"
                                                                                onClick={(e) => {
                                                                                    this.editRecipient(
                                                                                        e,
                                                                                        option.id,
                                                                                        option.name
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <i
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    e.nativeEvent.stopImmediatePropagation();
                                                                                    this.deleteRecipient(option.id);
                                                                                }}
                                                                                className="text-danger fas fa-trash"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        } else {
                                                            return (
                                                                <li className="w-100">
                                                                    {" "}
                                                                    <div className="row mx-0">
                                                                        <div className="col-12">{option.name}</div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Recipient"
                                                            placeholder="Select Management Recipient..."
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: { shrink: "mt-n1" },
                                                            }}
                                                            required={
                                                                this.state.recipientsList.length > 0
                                                                    ? true
                                                                    : false
                                                            }
                                                            fullWidth
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "disabled", // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <span className="text-danger">
                                                    {this.state.requestObject.recipient_err}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row m-0 my-3">
                                            <div className="col-md-6 mb-2 datepickerZindex">
                                                <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                                    <small>Date of Violation</small>
                                                </span>
                                                {/*<DatePickerInput
                          readonly="readonly"
                          onChange={(event, value) =>
                            this.updateChange(value, "DOV")
                          }
                          value={this.state.requestObject.dateOfVoilation}
                                            />*/}

                                                <DatePicker
                                                    dateForm="MM/DD/YYYY"
                                                    className="form-control bg-light rounded-0 "
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={(date) => {
                                                        if (moment(date).isValid()) {
                                                            this.updateChange(
                                                                moment(date).format("MM/DD/YYYY"),
                                                                "DOV"
                                                            );
                                                        } else {
                                                            this.updateChange(date, "DOV");
                                                        }
                                                    }}
                                                    selected={
                                                        this.state.requestObject.dateOfVoilation
                                                            ? moment(
                                                                this.state.requestObject.dateOfVoilation
                                                            ).toDate()
                                                            : moment(new Date()).toDate()
                                                    }
                                                    placeholderText={"MM/DD/YYYY"}
                                                />
                                                <span className="text-danger">
                                                    {this.state.requestObject.dateOfVoilation_err}
                                                </span>
                                            </div>

                                            <div className="col-md-6 datepickerZindex mb-2">
                                                <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                                    <small>Date Submitted</small>
                                                </span>
                                                {/*<DatePickerInput
                          readonly="readonly"
                          onChange={(event, value) =>
                            this.updateChange(value, "DateSubmit")
                          }
                          value={this.state.requestObject.dateSubmitted}
                                            />*/}

                                                <DatePicker
                                                    dateForm="MM/DD/YYYY"
                                                    className="form-control bg-light rounded-0 "
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={(date) => {
                                                        if (moment(date).isValid()) {
                                                            this.updateChange(
                                                                moment(date).format("MM/DD/YYYY"),
                                                                "DateSubmit"
                                                            );
                                                        } else {
                                                            this.updateChange(date, "DateSubmit");
                                                        }
                                                    }}
                                                    selected={
                                                        this.state.requestObject.dateSubmitted
                                                            ? moment(
                                                                this.state.requestObject.dateSubmitted
                                                            ).toDate()
                                                            : moment(new Date()).toDate()
                                                    }
                                                    placeholderText={"MM/DD/YYYY"}
                                                />
                                                <span className="text-danger">
                                                    {this.state.requestObject.dateSubmitted_err}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row m-0 my-3 topBottonMargin">
                                            <div className="col-md-6">
                                                <MDBInput
                                                    name=""
                                                    containerClass="my-0"
                                                    hint=" "
                                                    value={this.state.requestObject.subject}
                                                    label="Subject of Grievance"
                                                    type="text"
                                                    outline
                                                    maxlength="200"
                                                    onChange={(event, value) =>
                                                        this.updateChange(event.target.value, "Subject")
                                                    }
                                                />
                                                <span className="text-danger">
                                                    {this.state.requestObject.subject_err}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row m-0 my-3">
                                            {this.state.unionGrievance ? (
                                                <>
                                                    <div
                                                        className="col-md-6"
                                                    >
                                                        <Autocomplete
                                                            options={this.state.contractList}
                                                            classes={{
                                                                option: classes.option,
                                                            }}
                                                            autoComplete={true}
                                                            autoHighlight={true}
                                                            onChange={(event, value, reason) => {
                                                                event.preventDefault();
                                                                this.updateChange(value, "Bu_Contracts", null);

                                                            }}
                                                            value={this.state.contract}
                                                            getOptionLabel={(option) => {
                                                                if (option != undefined) {
                                                                    return option.contractName;
                                                                } else {
                                                                    return "";
                                                                }
                                                            }}
                                                            renderOption={(option) => (
                                                                <React.Fragment>{option.contractName}</React.Fragment>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Contract List"
                                                                    placeholder=""
                                                                    variant="outlined"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        classes: { shrink: "mt-n1" },
                                                                    }}
                                                                    fullWidth
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: "disabled", // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                        />


                                                    </div>
                                                    <div
                                                        className="col-md-6"
                                                    >
                                                        <Autocomplete
                                                            options={this.state.articlesList}
                                                            classes={{
                                                                option: classes.option,
                                                            }}
                                                            autoComplete={true}
                                                            autoHighlight={true}
                                                            onChange={(event, value, reason) => {
                                                                debugger;
                                                                console.log(event);
                                                                if (reason === "clear") {
                                                                    this.updateChange(value, "Contract", false);
                                                                } else {
                                                                    this.updateChange(value, "Contract", true);
                                                                }
                                                            }}
                                                            value={this.state.requestObject.contract}
                                                            getOptionLabel={(option) => {
                                                                if (option != undefined) {
                                                                    return option.name;
                                                                } else {
                                                                    return "";
                                                                }
                                                            }}
                                                            renderOption={(option) => (
                                                                <React.Fragment>{option.name}</React.Fragment>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Alleged Contract Article Violation"
                                                                    placeholder=""
                                                                    variant="outlined"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        classes: { shrink: "mt-n1" },
                                                                    }}
                                                                    fullWidth
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: "disabled", // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        <span className="text-danger">
                                                            {this.state.requestObject.contract_err}
                                                        </span>

                                                    </div>
                                                    <div className="col-md-6 offset-md-6">
                                                        {this.state.localContractsSelection.length > 0 && (
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Article Name</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.localContractsSelection.map(
                                                                        (item) => {
                                                                            // Check if item and item.id are not null or undefined before rendering
                                                                            let selectedContract = ""
                                                                            if (item &&
                                                                                item.id && !_.isEmpty(this.state.contractList)) {
                                                                                let selectedContractObj = this.state.contractList.find(contract => contract.id === item.contractId);
                                                                                if (selectedContractObj) {
                                                                                    selectedContract = selectedContractObj.contractName + " - ";
                                                                                }

                                                                            }


                                                                            return (<> {item &&
                                                                                item.id && (
                                                                                    <tr key={item.id}>
                                                                                        <td>{selectedContract}{item.name}</td>
                                                                                        <td style={{ fontSize: "16px" }}>
                                                                                            <span
                                                                                                style={{
                                                                                                    cursor: "help",
                                                                                                    marginRight: "15px",
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                    this.openInformationModal(item.id)
                                                                                                }
                                                                                            >
                                                                                                <i class="fas fa-info-circle" />
                                                                                            </span>
                                                                                            <span
                                                                                                onClick={() =>
                                                                                                    this.removeLocalArticleItem(
                                                                                                        item.id
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i
                                                                                                    className="fa fa-trash"
                                                                                                    aria-hidden="true"
                                                                                                ></i>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </>);
                                                                        }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        )}


                                                    </div>

                                                </>) : (

                                                <div className="col-md-12 my-0">
                                                    <div
                                                        className="col-md-6"
                                                        style={{ float: "left", marginLeft: "-17px" }}
                                                    >
                                                        <Autocomplete
                                                            options={this.state.articlesList}
                                                            classes={{
                                                                option: classes.option,
                                                            }}
                                                            autoComplete={true}
                                                            autoHighlight={true}
                                                            onChange={(event, value, reason) => {
                                                                debugger;
                                                                console.log(event);
                                                                if (reason === "clear") {
                                                                    this.updateChange(value, "Contract", false);
                                                                } else {
                                                                    this.updateChange(value, "Contract", true);
                                                                }
                                                            }}
                                                            value={this.state.requestObject.contract}
                                                            getOptionLabel={(option) => {
                                                                if (option != undefined) {
                                                                    return option.name;
                                                                } else {
                                                                    return "";
                                                                }
                                                            }}
                                                            renderOption={(option) => (
                                                                <React.Fragment>{option.name}</React.Fragment>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Alleged Contract Article Violation"
                                                                    placeholder=""
                                                                    variant="outlined"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        classes: { shrink: "mt-n1" },
                                                                    }}
                                                                    fullWidth
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: "disabled", // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        <span className="text-danger">
                                                            {this.state.requestObject.contract_err}
                                                        </span>
                                                    </div>

                                                    <div className="col-md-6" style={{ float: "right" }}>
                                                        {this.state.localContractsSelection.length > 0 && (
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Article Name</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.localContractsSelection.map(
                                                                        (item) => item &&
                                                                            item.id && (
                                                                                <tr key={item.id}>
                                                                                    <td>{item.name}</td>
                                                                                    <td style={{ fontSize: "16px" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                cursor: "help",
                                                                                                marginRight: "15px",
                                                                                            }}
                                                                                            onClick={() =>
                                                                                                this.openInformationModal(item.id)
                                                                                            }
                                                                                        >
                                                                                            <i class="fas fa-info-circle" />
                                                                                        </span>
                                                                                        <span
                                                                                            onClick={() =>
                                                                                                this.removeLocalArticleItem(
                                                                                                    item.id
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i
                                                                                                className="fa fa-trash"
                                                                                                aria-hidden="true"
                                                                                            ></i>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        )}


                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row m-0 my-3">
                                            <div className="col-md-12 my-0">
                                                <label>Statement of Facts</label>
                                                <div className="text-secondary">
                                                    <i>
                                                        <b>SOF GUIDANCE:</b> Briefly describe the event or
                                                        events causing harm to the grievant, followed by a
                                                        sentence identifying at least one agreement or
                                                        article of CBA violated by the Agency's actions
                                                        towards the grievant. Details about your argument or
                                                        the Agency's specific errors are not necessary.
                                                    </i>
                                                    <br />
                                                    <br />
                                                    <i>
                                                        <b>Example: </b>Grievant Jones was first on the
                                                        overtime list for the day shift on 9/22/23, but was
                                                        skipped by OS Smith when the overtime list was run.
                                                        This is a violation of the grievant's rights under
                                                        Article 38 of the CBA.
                                                    </i>
                                                    <br />
                                                    <br />
                                                    <i>
                                                        Please reach out to your Regional LR Team lead with
                                                        any questions.
                                                    </i>
                                                </div>
                                                <textarea
                                                    name=""
                                                    containerClass="mb-0"
                                                    className="w-100 border"
                                                    value={this.state.requestObject.statementOfFacts}
                                                    rows="5"
                                                    onChange={(event, value) =>
                                                        this.updateChange(event, "SOF")
                                                    }
                                                />
                                                <span className="text-danger">
                                                    {this.state.requestObject.statementOfFacts_err}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row m-0 my-3">
                                            <div className="col-md-12 my-0">
                                                <label>Remedy Sought</label>
                                                <textarea
                                                    name=""
                                                    containerClass="mb-0"
                                                    className="w-100 border"
                                                    value={this.state.requestObject.remedySought}
                                                    rows="5"
                                                    onChange={(event, value) =>
                                                        this.updateChange(event, "Remedy")
                                                    }
                                                />
                                                <span className="text-danger">
                                                    {this.state.requestObject.remedySought_err}
                                                </span>
                                            </div>
                                        </div>

                                        {/* Buttons */}
                                        <div className="row m-0 my-3">
                                            <div className="col-sm-12">
                                                <div className="text-right">
                                                    <button
                                                        className="btn btn-dark btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                                        type="button"
                                                        onClick={() => {
                                                            this.removeFromDraft(DraftKey);
                                                            this.props.history.push({
                                                                pathname: "/Grievances",
                                                                type: "list",
                                                            });
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="btn btn-info btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                                        onClick={() => {
                                                            this.submitGrievance(
                                                                true,
                                                                this.state.step1Active
                                                            );
                                                        }}
                                                        type="submit"
                                                    >
                                                        Save as Draft
                                                    </button>
                                                    <button
                                                        className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                                        onClick={() => {
                                                            this.submitGrievance(
                                                                false,
                                                                this.state.step1Active,
                                                                false
                                                            );
                                                        }}
                                                        type="submit"
                                                    >
                                                        Save Changes
                                                    </button>
                                                    <button
                                                        className="btn btn-warning btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                                        onClick={() => {
                                                            this.submitGrievance(
                                                                false,
                                                                this.state.step1Active,
                                                                true
                                                            );
                                                        }}
                                                        type="submit"
                                                    >
                                                        Submit to Exp. Arbitration
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GrievanceArticleVoilatedModal
                    modal={this.state.isConfirmModalOpen}
                    toggle={this.toggleConfirmModal}
                    html={this.state.contractArticleDetailsData}
                    header={this.state.contractArticleDetailsHeader}
                />
                <EditRecipient
                    Id={this.state.EditRecipientId}
                    Name={this.state.EditRecipientName}
                    ShowModal={this.state.showRecipientModal}
                    OnClose={this.closeRecipientModal}
                    GetRecipientByRepresentativeId={this.getRecipientByRepresentativeId}
                />
            </div>
        );
    }
}
export default withRouter(
    connect((state) => ({ grievanceState: state.grievances }), {
        ...GrievanceAction,
    })(GrievanceCreateForm)
);
