import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import TimesNewRoman from "../PDFFonts/Times New Roman.ttf";
import TimesNewRomanBold from "../PDFFonts/Times New Roman Bold.ttf";
import TimesNewRomanItalic from "../PDFFonts/FontsFree-Net-times-new-roman-italic.ttf";
import TimesNewRomanBoldItalic from "../PDFFonts/timesbi.ttf";

Font.register({
  family: "Times New Roman",
  fonts: [
    {
      src: TimesNewRoman,
    },
    {
      src: TimesNewRomanBold,
      fontWeight: "bold",
    },
    {
      src: TimesNewRomanItalic,
      fontStyle: "italic",
    },
    {
      src: TimesNewRomanBoldItalic,
      fontStyle: "italic",
      fontWeight: "bold",
    },
  ],
});
const styles = StyleSheet.create({
  GrienvantSecondFooter: {
    marginTop: 10,
    textDecoration: "underline",
    fontSize: 13,
  },
  grievanceHeaderLeft: {
    width: "50%",
  },
  container: {
    flexDirection: "row", // Align children horizontally
  },
  mB1: {
    marginBottom: 10,
  },
  w50: {
    width: "50%",
  },
  w35: {
    width: "35%",
  },

  w40: {
    width: "40%",
  },
  pL4: {
    paddingLeft: "34px",
  },
  p3: {
    padding: "21px",
  },

  pT4: {
    paddingTop: "34px",
  },
  pT3: {
    paddingTop: "21px",
  },
  pT2: {
    paddingTop: "13px",
  },
  pT1: {
    paddingTop: "3px",
  },
  pT0: {
    paddingTop: "0px",
  },
  boldText: {
    fontWeight: "bold",
  },

  redText: {
    color: "red",
  },

  grievanceHeaderRight: {
    width: "50%",
    paddingLeft: "15%",
  },
  headerImageView: {
    marginLeft: 100,
  },
  GrienvantFooter: {
    marginTop: 30,
  },
  image: {
    width: 80,
    height: 80,
    position: "relative",
    marginLeft: "50%",
  },
  floatLeft: {
    float: "left",
  },
  floatRight: {
    float: "right",
  },
  sec: {
    //marginTop: 80
  },
  Dear: {
    marginTop: 30,
    marginBottom: 30,
  },
  afterSalution: {
    marginTop: 10,
  },
  afterDateText: {
    marginTop: 30,
  },
  dateText: {
    marginTop: 50,
  },
  logoImage: {
    width: 80,
    height: 80,
  },
  body: {
    fontFamily: "Times New Roman",
    fontSize: 12,
    padding: 25,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // Use boxShadow for effects
  },
  bodyBoundry: {
    paddingTop: 35,
    paddingHorizontal: 35,
    paddingBottom: 65,
    height: "100%",
    border: "2px solid red",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // Use boxShadow for effects
  },
  title: {
    fontSize: 24,
    textAlign: "left",
    marginTop: 20,
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  textItalic: {
    fontStyle: "italic",
  },
  textCenter: { textAlign: "center" },
  textRight: { textAlign: "right" },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export function PARMultiPdfDocument(props) {
  if (!props.data || props.data.length === 0) {
    return null;
  }
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.bodyBoundry}>
          <View style={styles.container}>
            <View style={{ ...styles.boldText, ...styles.grievanceHeaderLeft }}>
              <Text style={styles.redText}>
                National Air Traffic Controllers Association
              </Text>
              <Text>AFL-CIO</Text>
              <Text style={styles.textItalic}>
                {props.data[0].regionDescription} ({props.data[0].regionName})
              </Text>
            </View>
            <View style={styles.grievanceHeaderRight}>
              <Image
                style={{ ...styles.image, ...styles.floatRight }}
                src="https://upload.wikimedia.org/wikipedia/en/6/67/NATCA_logo.png"
              />
              <Text
                style={{
                  ...styles.boldText,
                  ...styles.pT4,
                }}
              >
                {props.data[0].pdfGenerateDate}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.p3, ...styles.pT0 }}>
            <View>
              <Text
                style={{ ...styles.boldText, ...styles.GrienvantSecondFooter }}
              >
                SENT VIA EMAIL AND CERTIFIED MAIL:
              </Text>
              <Text style={{ ...styles.boldText, ...styles.pT3 }}>
                {props.data[0].recipientName}
              </Text>

              <Text>
                {props.data[0].recipientTitle}
              </Text>
              {props.data[0].memberFacilityAddress.split(';').map(x => (
                <Text style={{ ...styles.w40 }}>
                  {x}
                </Text>))}
            </View>

            <View style={{ ...styles.pT3 }}>
              <Text style={{ ...styles.boldText }}>
                Re: PAR Elevation Grievances
              </Text>
              <Text style={{ ...styles.pT3 }}>
                {props.data[0].recipientName}:
              </Text>
              <Text style={{ ...styles.pT3 }}>
                This letter is to request that, in accordance with the
                applicable Collective Bargaining Agreement, the following
                grievance(s) be submitted for Pre-Arbitration Review:
              </Text>
              {props.data[0].multipleFAAGrievanceNumbers.map(
                  (element, index) => {
                      let appliedStyle = { ...styles.pL4, ...styles.pT1 };
                      if (index == 0) {
                          appliedStyle = { ...styles.pL4, ...styles.pT3 };
                      }
                      return (
                          <Text key={index} style={appliedStyle}>
                              {index + 1}. {element.grievanceNumber}{" "}
                              {element.fAANumber ? `(${element.fAANumber})` : ""}
                          </Text>
                      );
                  }
              )}
            </View>
            <Text style={styles.pT2}>Regards,</Text>
            <Text style={styles.pT2}> {props.data[0].rvpName} </Text>
            <Text> Regional Vice President </Text>
            <Text> NATCA {props.data[0].regionDescription} </Text>
            {props.data[0].arvps &&
              props.data[0].arvps.map((arvp, index) => {
                let appliedStyle = styles.pT1;
                if (index == 0) {
                  appliedStyle = styles.pT3;
                }
                return (
                  <>
                    <Text style={appliedStyle}>
                      CC: {arvp.membername}, {props.data[0].regionName} Alternate
                      Regional Vice President
                    </Text>
                  </>
                );
              })}
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </Page>
    </Document>
  );
}
